import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import boton from '../Assets/Resources/9_Contacto/Enviar-01.png';
import Imagenbatman from '../Assets/Resources/9_Contacto/Imagen-01.png';
import Legal from '../Assets/Resources/9_Contacto/Legal-01.png';
import './Form.css'; 

const Form = ({ onBackClick }) => {
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [asunto, setAsunto] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleNombreChange = (event) => {
        setNombre(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleAsuntoChange = (event) => {
        setAsunto(event.target.value);
    };

    const handleMensajeChange = (event) => {
        setMensaje(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        const templateParams = {
            nombre: nombre,
            email: email,
            asunto: asunto,
            mensaje: mensaje,
            form: 'batmanrun',
        };

        emailjs.send('service_s4vvgaj', 'template_7rslm2z', templateParams, 'eiYNDxW4fyXp2qpw8')
            .then((result) => {
                console.log(result.text);
                setFormSubmitted(true);
                setIsSubmitting(false);
                // Reset form fields
                setNombre('');
                setEmail('');
                setAsunto('');
                setMensaje('');
            }, (error) => {
                console.error(error.text);
                setIsSubmitting(false);
            });
    };

    return (
        <>
        <div className="form-container">
            <div className="batman-image">
                <img src={Imagenbatman} alt="Batman" />
            </div>
            <div className="form-content">
                <button onClick={onBackClick} className="back-button">x</button>
                <h1>FORMULARIO DE CONTACTO</h1>
                <p>ESCRÍBENOS Y EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO.</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="nombre">*NOMBRE</label>
                    <input type="text" id="nombre" value={nombre} onChange={handleNombreChange} />
                    
                    <label htmlFor="email">*EMAIL</label>
                    <input type="email" id="email" value={email} onChange={handleEmailChange} />
                    
                    <label htmlFor="asunto">*ASUNTO</label>
                    <input type="text" id="asunto" value={asunto} onChange={handleAsuntoChange} />
                    
                    <label htmlFor="mensaje">*MENSAJE</label>
                    <textarea id="mensaje" value={mensaje} onChange={handleMensajeChange} />
                    
                    <div className="submit-container">
                        <button type="submit" className="submit-button">
                            <img src={boton} alt="Enviar" />
                        </button>
                    </div>
                    
                    {isSubmitting && <p>Cargando...</p>}
                    {formSubmitted && <p>Formulario enviado exitosamente!</p>}
                    {!formSubmitted && <p className="mandatory-fields">*LOS CAMPOS SON OBLIGATORIOS</p>}
                </form>
            </div>
        </div>
        <div className="legal-image">
            <img src={Legal} alt="Legal" />
        </div>
        </>
    );
};

export default Form;
