import React, { useState } from 'react';
import Header from './Components/Header';
import Main from './Pages/Main'; 
import Form from './Pages/Form';
import Preguntasfrecuentes from './Pages/Preguntasfrecuentes';
import './App.css';

function App() {
  const [showForm, setShowForm] = useState(false);
  const [mostrarFAQ, setMostrarFAQ] = useState(false);

  const HandleFAQClick = () => {
    setMostrarFAQ(true);
  };

  const handleContactClick = () => {
    setShowForm(true);
  };

  const handleBackClick = () => {
    setShowForm(false);
  };

  const HandleSalirClick = () => {
    setMostrarFAQ(false);
  }

  return (
    <>
      {!showForm && !mostrarFAQ && (
        <Header onContactClick={handleContactClick} onFAQClick={HandleFAQClick} />
      )}
      {showForm && ( 
        <Form onBackClick={handleBackClick}/>
        
      )}
      {mostrarFAQ && (
        <Preguntasfrecuentes onBackClick={HandleSalirClick} />
      )}
      {!showForm && !mostrarFAQ && (
        <Main/>

      )}
      
    </>
  );
}

export default App;
